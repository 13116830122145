




import { Component, Vue } from "vue-property-decorator";
import { DefaPageNotFound } from "@defa-as/components";

@Component({
  components: {
    DefaPageNotFound,
  },
})
export default class ViewError extends Vue {}
